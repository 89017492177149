.root {
  width: calc(94 * var(--mini-unit));
  height: calc(4 * var(--mini-unit));
}

.hasSiteSelector {
  width: calc(84 * var(--mini-unit));
}

.begin {
  background-color: var(--palette-background-input);
  min-width: calc(4 * var(--mini-unit));
  border-top-left-radius: var(--round-corners);
  border-bottom-left-radius: var(--round-corners);
  flex-shrink: 0;
  pointer-events: none;
}

.adornmentLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.beginStories {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.5;
  color: var(--palette-text-500);
  text-transform: uppercase;
  padding-right: calc(0.25 * var(--mini-unit));
}

.searchIcon {
  padding: 0 calc(0.5 * var(--mini-unit)) 0 calc(0.75 * var(--mini-unit));
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-500);
}

.end {
  min-width: calc(5 * var(--mini-unit));
  background-color: var(--palette-background-input);
  border-top-right-radius: var(--round-corners);
  border-bottom-right-radius: var(--round-corners);
  flex-shrink: 0;
}

.searchButton {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.28;

  background-color: #11435D;
  color: var(--palette-text-000);
  border-left: 1px solid #11435D;
  border-radius: var(--round-corners);

  padding: 0 calc(1 * var(--mini-unit));
  height: calc(4 * var(--mini-unit) - 4px);
  margin-right: var(--spacing-1);
}

.searchButton:disabled {
    cursor: pointer;
  }

.input {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.25;

  position: relative;
  display: block;
  padding: calc(0.5 * var(--mini-unit));
  box-sizing: border-box;
  width: 100%;
  align-self: stretch;
  color: var(--palette-text-100);
  border: 0;
  background-color: var(--palette-background-input);
  margin: 0;
}

.input:focus {
    outline: none;
  }

.input::-moz-placeholder {
    color: var(--palette-text-100);
    opacity: 0.5;
  }

.input::placeholder {
    color: var(--palette-text-100);
    opacity: 0.5;
  }

.input:-moz-read-only {
    opacity: 0.5;
  }

.input:read-only {
    opacity: 0.5;
  }

.input:disabled {
    opacity: 0.5;
  }

.inputWithTitle::-moz-placeholder {
    font-weight: var(--font-weight-primary-bold);
    color: var(--palette-text-500);
    opacity: 1;
  }

.inputWithTitle::placeholder {
    font-weight: var(--font-weight-primary-bold);
    color: var(--palette-text-500);
    opacity: 1;
  }
