.root {
  display: flex;
  width: calc(29 * var(--mini-unit));
  align-items: center;
  height: 34px;
}
.input {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
  position: relative;
  display: block;
  padding: var(--spacing-2);
  box-sizing: border-box;
  background-color: var(--palette-background-input);
  border-radius: var(--round-corners);
  border-style: solid;
  width: 100%;
  align-self: stretch;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--palette-text-500);
}
.input:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.input:read-only,
  .input:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.input:focus {
    outline: none;
  }
.input::-moz-placeholder {
    color: var(--palette-text-placeholder);
  }
.input::placeholder {
    color: var(--palette-text-placeholder);
  }
.adornment {
  margin-left: var(--spacing-2);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
}
.colorRegular {
  border: 1px solid var(--palette-grey-500);
}
.colorRegular:focus {
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }
.colorStreamBlue {
  border: 1px solid var(--palette-grey-500);
}
.colorStreamBlue:focus {
    border: 1px solid var(--palette-primary-500);
    box-shadow: inset 0px 0px 0px 1px var(--palette-primary-500);
  }
.colorDark {
  border: 1px solid var(--palette-grey-500);
}
.colorDark:focus {
    border: 1px solid #11435D;
    box-shadow: inset 0px 0px 0px 1px #11435D;
  }
.colorError {
  border: 2px solid var(--palette-error-500);
}
.fullWidth {
  width: 100%;
}
.textAlignCenter {
  text-align: center;
}
.seamlessAdornment {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.seamlessAdornment:focus {
    border-right: 0;
  }
.seamlessAdornment + .adornment {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: stretch;
  }
