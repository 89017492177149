.root {
  display: block;
  font-weight: var(--font-weight-primary-regular);
  padding: var(--spacing-2);
  color: #000000 !important;
}

.active {
  font-weight: var(--font-weight-primary-bold);
}
